.brand-logo {
  position: absolute;
  left: 3rem;
  top: 3rem;
}

.brand-logo h4 {
  font-weight: 700;
  font-size: 1.5vw;
}

.brand-logo img {
  width: 200px;
  height: auto;
}

.brand-logo {
  height: 'auto';
}

@media screen and (max-width: 768px){
  .brand-logo {
    left: 1rem;
    top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;;
  }

  .brand-logo img {
    width: 150px;
    height: auto;
    position: absolute;
    left: .5rem;
    top: 0.5rem;
    display: none;
  }
  .brand-logo h4 {
    font-size: 2.5vw;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
}