.p-button {
  font-size: 14px;
  font-weight: 500;
}

.p-selectbutton.p-buttonset .p-button {
  height: 2rem !important;
}

.p-selectbutton .p-button.p-highlight:hover {
  background-color: var(--default);
  filter: opacity(0.9)
}

.p-button.p-focus {
  box-shadow: none !important;
}