.p-megamenu.sdwan-toolbar {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.p-megamenu.sdwan-toolbar .p-menuitem-text {
  font-size: 14px !important;
}

.p-megamenu.sdwan-toolbar .p-megamenu-submenu-header {
  font-size: 14px !important;
}