.p-dropdown {
  display: inline-flex;
  width: 100%;
  border-radius: 6px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-dropdown .p-dropdown-label {
  font-size: 0.875rem !important;
  padding: 0;
}
.p-dropdown .p-dropdown-item {
  font-size: 0.875rem !important;
  padding: 0;
}
