@font-face {
    font-family: "Maven Pro";
    src: url("./../fonts/maven-pro/static/MavenPro-Regular.ttf");
}
@font-face {
    font-family: "Maven Pro";
    src: url("./../fonts/maven-pro/static/MavenPro-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Maven Pro";
    src: url("./../fonts/maven-pro/static/MavenPro-SemiBold.ttf");
    font-weight: 600;
}
@font-face {
    font-family: "Maven Pro";
    src: url("./../fonts/maven-pro/static/MavenPro-Bold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: "Maven Pro";
    src: url("./../fonts/maven-pro/static/MavenPro-Bold.ttf");
    font-weight: 900;
}