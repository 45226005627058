.p-dropdown {
  display: inline-flex;
  width: 100%;
  border-radius: 6px;
  height: 2rem;
  align-items: center;
}

.p-dropdown .p-dropdown-label {
  font-size: 0.875rem !important;
  padding: 0px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.p-dropdown .p-dropdown-item {
  font-size: 0.875rem !important;
  padding: 0px;
}