.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #2065AC !important;
  color: #ffffff !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  height: 3rem !important;
  width: 3rem !important;
  font-size: 1.5rem !important;
  min-width: 3rem !important;
}