//
// Badge floating
//


.btn {
    .badge-floating {
        position: absolute;
        top: -50%;
        transform: translateY(50%);
        border: 3px solid;

        &.badge:not(.badge-circle) {
            transform: translate(147%, 50%);
        }
    }
}

.nav-link {
    .badge-floating {
        position: absolute;
        top: -30%;
        right: 10%;
        transform: translateY(50%);
    }

    .badge-circle {
        width: 1rem;
        height: 1rem;
        font-size: .75rem;
    }

    .badge-danger {
        background-color: #f5365c;
        color: #fff;
    }
}
