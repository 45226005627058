.InputChips {
  .p-chips {
    .p-chips-multiple-container {
      padding: 0rem 0.5rem;
      
      .p-chips-input-token {
        padding: 0.15rem 0;
      }
      .p-chips-token {
        padding: 0.25rem 0.25rem;
      }
    }
  }
}