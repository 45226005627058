.customRefresh {

  .p-menu {
    width: auto;
    
    .p-menu-list{
      .p-menuitem {
        .p-menuitem-link {
          .p-menuitem-icon {
            margin-right: 0px;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .p-splitbutton {
    .p-button {
      background: white;
      border-color: var(--lighter);
      color: var(--primary);
    }
  }
  
}