.p-multiselect {
  display: inline-flex;
  width: 100%;
  border-radius: 5px;
  height: 2rem;
  align-items: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-multiselect .p-multiselect-label-container .p-multiselect-label {
  font-size: 0.875rem !important;
  padding: 0;
}
.p-multiselect .p-multiselect-item {
  font-size: 0.875rem !important;
  padding: 0;
}
